import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "./Header";
import ServicesContent from "./ServicesContent";

import Slider from "./Slider";
import Footer from "./Footer";
import Meta from "./Meta";
import LAN from "./../Languages/En";
import PageLinks from "./PageLinks";
import Breadcrumbs from "./Breadcrumbs";
import City_details from "../Languages/City_En";
class PagesEn extends Component {
  constructor(props) {
    super(props);
    City_details(this.props.match.params.city);

    this.state = {
      page_arr: [],
      page_url: this.props.match.params.handle,
      city_url: this.props.match.params.city
    };
  }
  page_detail() {
    //this.get_city_name();

    fetch(
      "https://www.moversanpackers.com/locationUpdate.php?url=" +
        this.props.match.params.handle +
        "&city_id=" +
        window.config.city
    )
      .then(results => {
        return results.json();
      })
      .then(data => {
        this.setState({ page_arr: data.items });
      })
      .catch(console.log);
  }

  page_redirect(val) {
    if (val === "") {
      return <Redirect to="/404.html" />;
    }
  }
  componentDidMount() {
    this.page_detail();
  }
  componentDidUpdate(nextProps, nextState) {
    if (nextState.page_url !== this.props.match.params.handle) {
      //console.log(nextState.page_url +'=='+this.props.match.params.handle);
      this.setState({ page_url: this.props.match.params.handle });
      this.page_detail();
      window.scrollTo(0, 0);
      return true;
    }
    return false;
  }
  componentWillUnmount() {
    this.setState({
      page_url: this.props.match.params.handle,
      city_url: this.props.match.params.city
    });
  }

  render() {
    //if(this.props.match.params.city != '')

    const page_arr = this.state.page_arr;
    const city_name = window.config.name_en;

    return (
      <div>
        {page_arr.map(item => (
          <span key={item.slug_url}>
            <Meta
              areaTitle={item.location_title}
              areaAddress={item.location_address}
              lan={LAN}
              city_name={city_name}
              mata={item.geo}
            />
            <Header
              areaTitle={item.location_title}
              areaAddress={item.location_address}
              lan={LAN}
              city_name={city_name}
            />
            <Breadcrumbs
              areaTitle={item.location_title}
              areaAddress={item.location_address}
              city_name={city_name}
              city_url={this.props.match.params.city}
            />
            <Slider
              areaTitle={item.location_title}
              areaAddress={item.location_address}
              lan={LAN}
              city_name={city_name}
            />

            <div className="about-section container">
              <h1>
                {LAN.TR_MOVERS} {LAN.TR_IN} {item.location_title} {city_name}
              </h1>
              <p>{item.location_address}</p>
              <p>{LAN.TR_TXT}</p>
              <p>
                {LAN.TR_PROF} <a href="tel:0503664575">0503664575 </a>
              </p>
              <p>{LAN.TR_CALLWHATSAPP}</p>
              <ul>
                <li>{LAN.TR_LI_0}</li>
                <li>{LAN.TR_LI_1}</li>
                <li>{LAN.TR_LI_2}</li>
                <li>{LAN.TR_LI_3}</li>
                <li>{LAN.TR_LI_4}</li>
                <li>{LAN.TR_LI_5}</li>
                <li>{LAN.TR_LI_6}</li>
                <li>{LAN.TR_LI_7}</li>
                <li>{LAN.TR_LI_8}</li>
                <li>{LAN.TR_LI_9}</li>
                <li>{LAN.TR_LI_10}</li>
                <li>{LAN.TR_LI_11}</li>
                <li>{LAN.TR_LI_12}</li>
                <li>{LAN.TR_LI_13}</li>
                <li>{LAN.TR_LI_14}</li>
                <li>{LAN.TR_LI_15}</li>
              </ul>

              <p>{LAN.TR_EXPREINCE}</p>
              <h2>
                {item.location_title} {LAN.TR_MOVERS} {LAN.TR_MOVING_STORAGE}
              </h2>
              <p>{LAN.TR_AMIN_SERVICE}</p>
              <ul>
                <li>{LAN.TR_LI16}</li>
                <li>{LAN.TR_LI17}</li>
                <li>{LAN.TR_LI18}</li>
                <li>{LAN.TR_LI19}</li>
                <li>{LAN.TR_LI20}</li>
                <li>{LAN.TR_LI21}</li>
                <li>{LAN.TR_LI22}</li>
              </ul>
              <h2>{LAN.TR_REMOVER}</h2>
              <p>{LAN.TR_REMOVER_TXT.replace("{CITY}", city_name)}</p>
              <h3>{LAN.TR_REMOVING}</h3>
              <p>
                {LAN.TR_REMOVING_TXT.replace("{CITY}", city_name)}
                <br />
                {LAN.TR_REMOVING_TXT2.replace("{CITY}", city_name).replace(
                  "{CITY}",
                  city_name
                )}
                <br />
                {LAN.TR_REMOVING_TXT3.replace("{CITY}", city_name)}
              </p>
            </div>
            <PageLinks
              url={this.props.match.params.handle}
              city_url={this.state.city_url}
              page="all"
            />
            <ServicesContent
              areaTitle={item.location_title}
              areaAddress={item.location_address}
              lan={LAN}
              city_url={this.state.city_url}
              city_name={city_name}
            />
            <Footer
              areaTitle={item.location_title}
              areaAddress={item.location_address}
              lan={LAN}
              city_name={city_name}
              city_url={this.props.match.params.city}
            />
          </span>
        ))}
      </div>
    );
  }
}

export default PagesEn;
