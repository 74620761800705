import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import aboutImg  from './img/aboutImg.jpg';
class AboutContent extends Component{
       
    render(){
		var cityName = window.config.name;
		if(this.props.areaTitle !==''){
			cityName = this.props.areaTitle;
		}
        return(
            
            <Container className="about-section container-fluid no-left-padding no-right-padding">
                <Row className="container">
                    <Col className="about-img col-md-6 col-12">
                        <img width="458" height="324" src={aboutImg} className="" alt="" />
                    </Col>
                    <Col className="container col-md-6 col-12">
                        <div className="section-header pt-4 ">
                            <h5>معلومات عنا</h5>
        <h3>مرحبا بك في <span>{cityName} نقل اثاث</span></h3>
                        </div>
                        <div className="about-content">
                            <p>شركة نقل عفش الشارقة هى شركة أمين نقل اثاث في مجال نقل العفش، لدينا خبرة طويلة فى فك وتركيب وتغليف ونقل العفش، نقل آمن، بدون خدوش أو كسور وبدون فقدان أى قطعة أثاث، وبدون تضرر أي قطعة أثاث.</p>
                            <h5>بعض لدينا <span>اخلاص,</span></h5>
                            <ul>
                                <li>أعمالنا تفعل سريع جدا.</li>
                                <li>النقل التجاري (المكاتب والفنادق والمستودعات وغيرها)</li>
                                <li>موظفي التعبئة المدربين تدريبا عاليا.</li>
                                <li>»تجميع أثاث العبوات المسطحة (ايكيا ، هوم سنتر ، إلخ)</li>
                            </ul>
                            <a href="/aboutus.html" title="قراءة المزيد">قراءة المزيد</a>
                        </div>
                    </Col>
                </Row>
            </Container>
            
        )
    }


}

export default AboutContent;