
var lan={
    TR_IN : 'in',
    TR_MOVERS : 'Movers',
    TR_TXT : 'We are professional movers, Amin Furniture Movers the best players in moving house, changing villa, moving office',
    TR_PROF: 'Call or WhatsApp on this number View phone number anytime, we work on weekends as well.',
    TR_CALLWHATSAPP: 'Call or WhatsApp on this number 050 212 9142 any time, we are working on weekends as well.',
    TR_LI_0 : 'Residential Moving ( Flat, Apartments, Villas)',
    TR_LI_1 : 'Commercial Moving (Offices, Hotels, Warehouse etc)',
    TR_LI_2 : 'Highly Trained Packing Staff.',
    TR_LI_3 : 'Expert Carpenters',
    TR_LI_4 : 'Expert Handyman services',
    TR_LI_5 : 'Cargo Packing Staff',
    TR_LI_6 : 'Assembling Of Flat Pack Furniture ( IKEA, Home Center, etc)',
    TR_LI_7 : 'Packing and Shifting services',
    TR_LI_8 : 'Disposal Service.',
    TR_LI_9 : 'Furniture Dismantling',
    TR_LI_10 : 'Furniture Assembling',
    TR_LI_11 : 'LCD & Bracket Fixing',
    TR_LI_12 : 'Curtains & Blinds Rods Fixing',
    TR_LI_13 : 'Providing Bubble Rap',
    TR_LI_14 : 'Provision Cotton Role',
    TR_LI_15 : 'Boxes and Packing Material supplies.',
    TR_EXPREINCE: 'We have 15+ years experience in moving. We provided all kinds of mobile services. We have an expert team full of carpenters and packing. We have a 3 ton pickup and a small pickup for a small company as well. Packer & Moving in Sharjah is one of the leading companies in the UAE. Amin is the only name for effective mobilization and relocation in Sharjah. From the very beginning, our team relieves your stress by filling in the site on time and starting the packing procedure. The goods are safely transported to the new location, unloaded and installed carefully. Time management is the honest quality that we boast. T over the years Amin has not made any compromise in them. Call or whats aap on this number',
    TR_MOVING_STORAGE: 'Moving and storage',
    TR_AMIN_SERVICE: 'Amin Movers always seeks to provide integrated solutions and products to save and store the transfer of furniture and documents taking into account the type of furniture transfer and documents to be saved, save the tapes and different from the need to save engineering schemes, because each type of furniture transfer suits a specific type of storage systems',
    TR_LI16: 'Easy movement and moving between furnitures.',
    TR_LI17: 'High security (central mind).',
    TR_LI18: 'Versatile.',
    TR_LI19: 'Flexible manufacturing (suitable for all available spaces).',
    TR_LI20: 'Double the storage capacity of more than 80% in moving/big/small furniture.',
    TR_LI21: 'The possibility of adding future more space and units.',
    TR_LI22: 'Aesthetic shape',
    TR_REMOVER: 'Furniture Movers',
    TR_REMOVER_TXT: 'With Moving & Moving {CITY}, goodbye to the concern you felt when moving your home furniture. We transport professionally and without any scratches or breaks. Moving luggage inside and outside the city',
    TR_REMOVING: 'Moving shifting Furniture',
    TR_REMOVING_TXT: 'Moving furniture {CITY} The company offers great discounts and discounts to all new customers on all the furniture transfers',
    TR_REMOVING_TXT2: 'Through which to make you happy and provide you with the service you are looking for moving furniture Our company originated at a time full of luggage companies but hard work and the quality of our services and we have reached this high status among the furniture companies {CITY}. Confused and workers revolve on the cheapest and best companies to move furniture {CITY}?',
    TR_REMOVING_TXT3:'Do you have the right company in terms of trained workers and closed Arab cars and also be cheap and suitable for you and others? Moving and moving the company surprise to move the luggage in Kteier and Mbtkhas. Cheapest moving and cheap furniture moving companies {CITY} and is the cheapest basis among the moving companies',





  }

export default lan;
