//var city_En={
  //  sharjah : {city_id :1,city_name:'Sharjah'},

  //}
  const City_details = (city_url) => {
    var city_name = 'Sharjah';
    var city_id = '1';
    switch(city_url){
      case 'dubai':
        city_id = 2;
        city_name = 'Dubai';
      break;
      case 'abudhabi':
        city_id = 6;
        city_name = 'Abu dhabi';
      break;
      case 'alain':
        city_id = 3;
        city_name = 'Al Ain';
      break;
      case 'rak':
        city_id = 3;
        city_name = 'Rasal Khaima';
      break;
      case 'ajman':
        city_id = 17;
        city_name = 'Ajman';
      break;
      case 'fujaira':
        city_id = 5;
        city_name = 'Fujaira';
      break;
      case 'uaq':
        city_id = 8;
        city_name = 'Umm al-Quwain';
      break;
      default:
        city_id = 1;
        city_name = 'Sharjah';
        break;
    }
    window.config.city = city_id;
    window.config.name_en = city_name;
  }

  

export default City_details;