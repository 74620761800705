import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import slide1 from './../img/slider-1.jpg';
import slide2 from './../img/slider-2.jpg';
import slide3 from './../img/slider-3.jpg';

//var cityName = window.config.name_en; 


const items = [ 
  {
    src: slide1,
    altText: 'Slide 1',
    caption: 'Ready to Plan your next Move in {CITY} {LOCATION}, we are the only professional furniture movers to handle your things with carefully'
  },
  {
    src: slide2,
    altText: 'Slide 2',
    caption: 'We help to Your Move for your home, office and villas movers in {CITY} {LOCATION}'
  },
  {
    src: slide3,
    altText: 'Slide 3',
    caption: 'Move your home in any where in emirates with us and we help you call us @ 0503664575'
  }
];

const Slider = (props) => {
  var city_name = props.city_name;
  var location_name = props.areaTitle;
 // console.log(window.config);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText.replace('{CITY}', city_name).replace('{LOCATION}', location_name)} />
        <CarouselCaption captionText={item.caption.replace('{CITY}', city_name).replace('{LOCATION}', location_name)} captionHeader={item.caption.replace('{CITY}', city_name).replace('{LOCATION}', location_name)} />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default Slider;