import React from 'react';
import{
    Router,
    Route, 
    Switch
} from 'react-router-dom';
import App from './App';
import AppEn from './en/App';
import Pages from './Pages';
import Aboutus from './Aboutus';
import AboutusEn from './en/Aboutus';
import ServicesEn from './en/Services';
import Services from './Services';
import PagesEn from './en/Pages';
import City from './en/City'; // <Route path="/en/:city/:handle" component={City}  />
import CityAr from './City'; 
import NotFound from './NotFound';
import './App.css';
import { createBrowserHistory as createHistory } from 'history';
const customHistory = createHistory();

const CustomeRouter = (props) =>(
    
    <Router history ={customHistory}>
        
        <Switch>
            <Route exact path='/' component={App} />
            <Route exact path='/en/' component={AppEn} />
            <Route exact path='/index.html' component={App} />
            <Route exact path='/en/aboutus.html' component={AboutusEn} />
            <Route exact path='/en/services.html' component={ServicesEn} />
            <Route exact path='/aboutus.html' component={Aboutus} />
            <Route exact path='/services.html' component={Services} />
            <Route exact path='/404.html' component={NotFound} />
            <Route path="/en/:city/:handle" component={PagesEn}  />            
            <Route path="/en/:city/" component={City}  />
            
            <Route path='/:city/:handle' component={Pages} /> 
            <Route path="/:city/" component={CityAr}  />                  
            
        </Switch>

    </Router>

)
export default CustomeRouter;