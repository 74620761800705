import React, { Component } from "react";
import { Link } from "react-router-dom";
import City_details from "../Languages/City_En";
class PageLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_arr: [],
      city_name: "",
      city_url: ""
    };
  }
  componentDidUpdate(nextProps, nextState) {
    if (nextProps.city_url !== this.props.city_url) {
      var url = "";
      if (this.props.url !== "") {
        url = this.props.url;
      }

      var city_url = "";
      if (this.props.city_url !== "") {
        city_url = this.props.city_url;
        City_details(city_url);
        this.setState({ city_name: window.config.name_en });

        city_url = city_url + "/";
      }
      var page = "all";
      if (this.props.page !== "") {
        page = this.props.page;
      }

      fetch(
        "https://www.moversanpackers.com/locationUpdate.php?page=" +
          page +
          "&url=" +
          url +
          "&city_id=" +
          window.config.city
      )
        .then(results => {
          return results.json();
        })
        .then(data => {
          this.setState({ page_arr: data.items });
        })
        .catch(console.log);
      window.scrollTo(0, 0);
    }
  }
  componentDidMount() {
    var url = "";
    if (this.props.url !== "") {
      url = this.props.url;
    }

    var city_url = "";
    if (this.props.city_url !== "") {
      city_url = this.props.city_url;
      City_details(city_url);
      city_url = city_url + "/";
    }
    var page = "all";
    if (this.props.page !== "") {
      page = this.props.page;
    }
    this.setState({ city_name: window.config.name_en });
    this.setState({ city_url: city_url });

    fetch(
      "https://www.moversanpackers.com/locationUpdate.php?page=" +
        page +
        "&url=" +
        url +
        "&city_id=" +
        window.config.city
    )
      .then(results => {
        return results.json();
      })
      .then(data => {
        this.setState({ page_arr: data.items });
      })
      .catch(console.log);
  }

  render() {
    const aStyle = {
      fontSize: "14px"
    };
    const lStyle = {
      color: "#000"
    };
    const city_name = this.state.city_name;
    const page_arr = this.state.page_arr;
    const city_url = this.state.city_url;
    return (
      <div className="link-section container-fluid no-left-padding no-right-padding container">
        <div className="container">
          <h3 className="widget-title">
            <Link style={lStyle} to={"/en/" + city_url}>
              Our furniture movers services in {city_name}
            </Link>
          </h3>
          <ul className="list-unstyled row">
            {page_arr.map(item => (
              <li
                className="list-item col-12 col-lg-3 col-sm-6  border-top py-2"
                key={item.slug_url}
              >
                {item.status === "1" ? (
                  <Link
                    style={aStyle}
                    to={"/en/" + city_url + item.slug_url}
                    title={item.location_title + " Movers"}
                  >
                    {item.location_title} Movers
                  </Link>
                ) : (
                  <span title={item.location_title}>
                    {item.location_title} Movers
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
export default PageLinks;
