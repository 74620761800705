import React, { Component } from "react";
import { Helmet } from "react-helmet";

class Meta extends Component {
  render() {
    var title = "نقل اثاث";
    var cityName = "الشارقة";
    if (this.props.areaTitle !== "") {
      title = this.props.areaTitle;
      cityName = this.props.city_name;
    }
    var mata = "";
    if (this.props.mata !== undefined) {
      mata = this.props.mata;
      //mata = mata.toString().replace(/meta/g, 'meta data-react-helmet="true"');
    }
    //console.log(mata);

    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {title} في {cityName} | شركة أمين نقل اثاث 0503664575
        </title>

        <meta
          name="description"
          content={
            title +
            " في " +
            cityName +
            " | شركة نقل عفش في " +
            cityName +
            " نقل اثاث مع الفك والتركيب والتغليف بأفضل الاسعار "
          }
        />
        {mata}
      </Helmet>
    );
  }
}

export default Meta;
