import React, {Component} from 'react';
import { Container, Row } from 'reactstrap';
import Navbar from './Navbar';
class Header extends Component{
    constructor(props){
        super(props);
        window.scrollTo(0, 0);
      }
    
    render(){
        return(
        <header className="header_s1">
            <div className="container-fluid top-header">
                <Container>
                    <Row className="contact-block">
                        <p>
                            <i className="icon icon-Phone2"></i> 
                            <a href="tel:0503664575" title="050 366 45 75">050 366 45 75</a>
                        </p>                       
                        <p>
                            <i className="icon icon-Time"></i>الجمعة - السبت 24/7
                        </p>
                    </Row>                    
                </Container>
            </div>
            <div>
            </div>
            <Navbar/>
        </header>
        );
    }
}

export default Header;