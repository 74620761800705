import React, {Component} from 'react';
import { Row, Col  } from 'reactstrap';
import {
    
    Link 
} from 'react-router-dom';
import logo from './../img/logo.png';

class Navbar extends Component{
    render(){
        return(
            <nav className="navbar ownavigation " >
            
			<div className="container">
                <Row className="navbar-header">
                    <Col s="5" className="float-left col-md-5 col-12">
                        <Link to='/' className="navbar-brand image-logo"  ><img src={logo} alt="Furniture Movers" /></Link>
                    </Col>
                    <Col  className="float-right col-md-7 col-md-7 col-12">
                        <div id="navbar" className=" navbar-collapse navbar-right">
                            <ul className="nav navbar-nav ">
                                <li  >
                                    <Link title="Home" to="/en/" className="nav-item nav-link active" aria-haspopup="true">Home</Link>
                                </li>
                                <li ><Link className="nav-item nav-link "title="Services" to="/en/services.html">Services</Link></li>
                                <li ><Link className="nav-item nav-link "title="Services" to="/">العربية</Link></li>
                            </ul>
                        </div>
                    </Col>

                
                </Row>
                
            </div>
		</nav>
            );
    }
    
}



export default Navbar;