import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "./Header";
import ServicesContent from "./ServicesContent";

import Slider from "./Slider";
import Footer from "./Footer";
import Meta from "./Meta";
import PageLinks from "./PageLinks";
import Breadcrumbs from "./Breadcrumbs";
import City_details from "./Languages/City_Ar";
class Pages extends Component {
  constructor(props) {
    super(props);
    City_details(this.props.match.params.city);

    this.state = {
      page_arr: [],
      page_url: this.props.match.params.handle,
      city_url: this.props.match.params.city
    };
  }
  page_detail() {
    //this.get_city_name();

    fetch(
      "https://www.moversanpackers.com/locationUpdate.php?url=" +
        this.props.match.params.handle +
        "&city_id=" +
        window.config.city
    )
      .then(results => {
        return results.json();
      })
      .then(data => {
        this.setState({ page_arr: data.items });
      })
      .catch(console.log);
  }

  page_redirect(val) {
    if (val === "") {
      return <Redirect to="/404.html" />;
    }
  }
  componentDidMount() {
    this.page_detail();
  }
  componentDidUpdate(nextProps, nextState) {
    if (nextState.page_url !== this.props.match.params.handle) {
      //console.log(nextState.page_url +'=='+this.props.match.params.handle);
      this.setState({ page_url: this.props.match.params.handle });
      this.page_detail();
      window.scrollTo(0, 0);
      return true;
    }
    return false;
  }
  componentWillUnmount() {
    this.setState({
      page_url: this.props.match.params.handle,
      city_url: this.props.match.params.city
    });
  }

  render() {
    //if(this.props.match.params.city != '')

    const page_arr = this.state.page_arr;
    const city_name = window.config.name_en;
    //console.log(window.config.name);
    return (
      <div>
        {page_arr.map(item => (
          <span key={item.slug_url}>
            {this.page_redirect(item.location_title_ar)}

            <Meta
              areaTitle={item.location_title_ar}
              areaAddress={item.location_address_ar}
              city_name={city_name}
              mata={item.geo}
            />
            <Header
              areaTitle={item.location_title_ar}
              areaAddress={item.location_address_ar}
              city_name={city_name}
            />
            <Breadcrumbs
              areaTitle={item.location_title_ar}
              areaAddress={item.location_address_ar}
              city_name={city_name}
              city_url={this.props.match.params.city}
            />

            <Slider
              areaTitle={item.location_title_ar}
              areaAddress={item.location_address_ar}
              city_name={city_name}
            />

            <div className="about-section container">
              <h1>
                {item.location_title_ar} في {city_name}
              </h1>
              <p>
                نقل اثاث {item.location_address_ar} في {city_name}{" "}
              </p>
              <p>
                نحن المحركون المحترفون ، شركة أمين نقل اثاثهم أفضل اللاعبين في
                نقل المنازل ، وتغيير الفيلا ، ونقل المكاتب.
                <br />
                اتصل أو واتساب على هذا الرقم اعرض رقم الهاتف في أي وقت ، ونحن
                نعمل في عطلة نهاية الأسبوع كذلك.
              </p>
              <br />
              <ul>
                <li>»النقل السكني (شقة ، شقق ، فلل)</li>
                <li>»النقل التجاري (المكاتب والفنادق والمستودعات وغيرها)</li>
                <li>»موظفي التعبئة المدربين تدريبا عاليا.</li>
                <li>»خبير النجارين</li>
                <li>»خدمات الخبير الماهر</li>
                <li>»طاقم شحن البضائع</li>
                <li>»تجميع أثاث العبوات المسطحة (ايكيا ، هوم سنتر ، إلخ)</li>
                <li>»خدمات التغليف والتغيير</li>
                <li>»خدمة التخلص.</li>
                <li>»تفكيك الأثاث</li>
                <li>»شاشات الكريستال السائل وتحديد قوس</li>
                <li>»الستائر والستائر قضبان تثبيت</li>
                <li>»الستائر والستائر قضبان تثبيت</li>
                <li>»توفير دور القطن</li>
              </ul>

              <p>
                لدينا 15+ سنة خبرة في التحرك. قدمنا ​​كل أنواع الخدمات المتحركة.
                لدينا فريق خبراء كامل من النجارين والتعبئة. لدينا سيارة بيك آب 3
                طن وصغيرة لشركة الصغيرة أيضًا. شركة باكر آند موفينج في الشارقة
                هي واحدة من الشركات الرائدة في دولة الإمارات العربية المتحدة.
                أمين هو الاسم الوحيد للتعبئة الفعالة والانتقال في الشارقة. منذ
                البداية ، يخفف فريقنا من إجهادك بالتعبئة عن طريق الوصول إلى
                الموقع في الوقت المحدد وبدء إجراءات التعبئة. يتم نقل البضائع
                بأمان إلى الموقع الجديد وتفريغها وتثبيتها بعناية. إدارة الوقت هي
                نوعية أمينالتي نتباهى بها. T على مر السنين لم تقدم أمينأي حل وسط
                فيها. اتصل أو ماذا يكون aap على هذا الرقم
              </p>

              <h2>
                {item.location_title_ar} {city_name} الانتقال والتخزين
              </h2>
              <p>
                {" "}
                تسعى شركة أمين دائماً في توفير حلول ومنتجات متكاملة لحفظ وتخزين
                نقل اثاث والوثائق مع الاخذ في الاعتبار نوع نقل اثاث والوثائق
                المراد حفظها ، حفظ الأشرطة وتختلف عن حاجة حفظ المخططات الهندسية
                ،لان كل نوع من انواع نقل اثاث يناسبه نوع محدد من انواع انظمة
                التخزين{" "}
              </p>
              <ul>
                <li>سهولة الحركة والانتقال بين نقل اثاث.</li>
                <li>امان عالي ( فقل مركزي ).</li>
                <li>متعددة الاستخدامات.</li>
                <li>مضاعفة الطاقة التخزينية لاكثر من 80% في نقل اثاث.</li>
                <li>مرونة التصنيع ( تتناسب مع جميع المساحات المتوفرة).</li>
                <li>امكانية اضافة وحدات مستقبلية.</li>
                <li>الشكل الجمالي.</li>
              </ul>
              <h2>نقل عفش {item.location_title_ar}</h2>
              <p>
                {" "}
                مع شركة نقل عفش الشارقة وداعا للقلق الذى كنت تشعر به عند نقل
                اثاث منزلك . نحن نقوم بالنقل باحترافية تامة وبدون اى خدوش او
                كسور. نقل العفش داخل وخارج المدينه
              </p>
              <p>
                نقل عفش {city_name}, ان عملية نقل الاثاث من المشاكل التي تواجه
                الكثير منا وتسبب القلق والشعور بالخوف علي الموبيليا الخاصه بك من
                التلف أثناء النقل وذلك لتكلفتها العالية وما تحتاج اليه من مجهود
                و وقت حتي يتم انجازه بنجاح نقل عفش لذا سنقدم لكم اليوم مجموعة من
                الافكار المهمة نقل عفش {city_name} التي تسهل عليكم هذه العملية
                وتساعدكم علي نقل العفش و الموبيليا بسهولة ويسر بدون تعب او
                مجهود. هل تبحث عن أرخص شركة نقل عفش {city_name}؟ شركة نقل فاذا
                كنت تبحث عن أرخص شركة نقل عفش {city_name} فانحن الافضل و الارخص
                في هذا المجال
              </p>
              <h3>
                نقل عفش {city_name} رخيص {item.location_title_ar}
              </h3>
              <p>
                نقل اثاث {city_name} فالشركة تقدم عروض و خصومات هايلة لكل
                العملاء الجدد علي جميع عمليات نقل العفش
                <br />
                التي تتم من خلالها لاسعادكم و امدادكم بالخدمة التي تبحثون عنها
                نقل اثاث فشركتنا نشأت في وقت ملئ بشركات نقل العفش لكن باجتهادنا
                و بجودة خدماتنا وصلنا الي هذه المكانة العالية وسط شركات نقل
                الاثاث {city_name}. إحتارت وعمال تدور علي أرخص وأفضل شركات لنقل
                اثاث {city_name} ؟!
                <br />
                بتدور علي الشركة المناسبة من حيث العمالة المدربة والعربيات
                المغلقة وكمان تكون أسعارها رخيصة ومناسبة ليك ولغيرك؟ نقل عفش
                مفاجأة الشركة لنقل العفش في كتيير ومبتخلصش . نقل عفش {
                  city_name
                }{" "}
                رخيص أقدم وأرخص شركات نقل الاثاث {city_name} وهي الأساس الأرخص
                بين شركات نقل العفش {city_name}
              </p>
            </div>
            <PageLinks
              url={this.props.match.params.handle}
              city_url={this.state.city_url}
              page="all"
            />
            <ServicesContent
              areaTitle={item.location_title_ar}
              areaAddress={item.location_address_ar}
              city_url={this.props.match.params.city}
              city_name={city_name}
            />
            <Footer
              areaTitle={item.location_title}
              areaAddress={item.location_address}
              city_name={city_name}
              city_url={this.props.match.params.city}
            />
          </span>
        ))}
      </div>
    );
  }
}

export default Pages;
