import React, { Component } from "react";
import "./App.css";

import Header from "./Header";
import ServicesContent from "./ServicesContent";
import Footer from "./Footer";
import Meta from "./Meta";
import Breadcrumbs from "./Breadcrumbs";
class Aboutus extends Component {
  constructor(props) {
    super(props);
    document.title = "Our services";
    // document.description = 'Our services';
  }

  render() {
    var title = "نقل اثاث";
    var cityName = "الشارقة";
    if (this.props.areaTitle !== "") {
      title = this.props.areaTitle;
      //cityName = this.props.areaTitle;
    }
    return (
      <div>
        <Header />
        <Meta
          areaTitle=" خدماتنا "
          areaAddress=""
          city_name={cityName}
          city_url="sharjah"
        />
        <Breadcrumbs
          areaTitle=" خدماتنا "
          areaAddress={title}
          city_name={cityName}
          city_url="sharjah"
        />
        <ServicesContent areaTitle={this.props.areaTitle} />
        <Footer />
      </div>
    );
  }
}

export default Aboutus;
