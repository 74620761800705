import React, { Component } from "react";

import Header from "./Header";

import AboutContent from "./AboutContent";
import ServicesContent from "./ServicesContent";
import Footer from "./Footer";
import PageLinks from "./PageLinks";
import Meta from "./Meta";
import Slider from "./Slider";
import City_details from "../Languages/City_En";
import Breadcrumbs from "./Breadcrumbs";
class City extends Component {
  render() {
    City_details(this.props.match.params.city);
    var name = window.config.name_en;
    //console.log(this.props.match.params.city);
    //console.log(name);
    return (
      <div>
        <Meta
          areaTitle="Furniture movers & packers"
          areaAddress={name}
          city_name={name}
        />
        <Header
          areaTitle="Furniture movers & packers"
          areaAddress={name}
          city_name={name}
        />
        <Breadcrumbs
          areaTitle={name}
          areaAddress={name}
          city_name={name}
          city_url=""
        />
        <Slider
          areaTitle="Furniture movers & packers"
          areaAddress={name}
          city_name={name}
          city_url="1"
        />
        <AboutContent
          areaTitle="Furniture movers & packers"
          areaAddress={name}
          city_name={name}
        />
        <ServicesContent
          areaTitle={name}
          areaAddress={name}
          city_url={this.props.match.params.city}
          city_name={name}
        />

        <PageLinks url="" city_url={this.props.match.params.city} page="all" />

        <Footer city_url={this.props.match.params.city} />
      </div>
    );
  }
}

export default City;
