import React, { Component } from "react";
import whatsapp from "./img/whatsapp.png";
import footer from "./img/footer.png";
//import ReactGA from 'react-ga';
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

class Footer extends Component {
  render() {
    var areaAddress = "";
    var cityName = window.config.name_en;
    var city_url = "/sharjah/";
    if (this.props.areaAddress !== "") {
      areaAddress = this.props.areaAddress;
    }
    if (this.props.city_url !== "") {
      city_url = "/" + this.props.city_url + "/";
    }
    return (
      <footer
        id="footer-main"
        className="footer-main container-fluid no-left-padding no-right-padding"
      >
        <div className="container">
          <Row className="footer-address-box">
            <Col className="col-md-4 col-sm-6 col-xs-6">
              <div className="address-info">
                <p>
                  شركه أمين لنقل الأثاث {cityName} <br /> {areaAddress}
                  <i className="icon icon-Pointer"></i>
                </p>
              </div>
            </Col>
            <Col className="col-md-4 col-sm-6 col-xs-6">
              <div className="address-info">
                <p>
                  <i className="icon icon-Phone2"></i>
                  <a href="tel:18001234567" title="18001234567">
                    مكتب: 050-366-45-75
                  </a>
                  <br />
                  <a href="tel:00971503664575" title="00971503664575">
                    واتس آب: 00971503664575
                  </a>
                </p>
              </div>
            </Col>
            <Col className="col-md-4 col-sm-6 col-xs-6">
              <div className="address-info">
                <p>
                  <i className="icon icon-Timer"></i> الجمعة - السبت 24/7
                </p>
              </div>
            </Col>
          </Row>
          <div className="col-md-4 col-sm-6 col-xs-6">
            <aside id="widget-about-1" className="widget widget_about">
              <h3 className="widget-title">معلومات عنا</h3>
              <ul id="menu-our-services" className="menu">
                <li>
                  <Link to="/dubai/">نقل اثاث دبي</Link>
                </li>
                <li>
                  <Link to="/sharjah/">نقل اثاث الشارقة</Link>
                </li>
                <li>
                  <Link to="/abudhabi/">نقل اثاث ابوظبي</Link>
                </li>
                <li>
                  <Link to="/alain/">نقل اثاث العين</Link>
                </li>
                <li>
                  <Link to="/ajman/">نقل اثاث عجمان</Link>
                </li>
                <li>
                  <Link to="/rak/">نقل اثاث راس الخيمه</Link>
                </li>
                <li>
                  <Link to="/uaq/">لنقل اثاث بالامارات</Link>
                </li>
                <li>
                  <Link to="/fujaira/">نقل اثاث الفجيرة</Link>
                </li>
              </ul>
              <p>
                شركة نقل عفش الشارقة هى شركة أمين نقل اثاث في مجال نقل العفش،
                لدينا خبرة طويلة فى فك وتركيب وتغليف ونقل العفش، نقل آمن، بدون
                خدوش أو كسور وبدون فقدان أى قطعة أثاث، وبدون تضرر أي قطعة أثاث.
              </p>
              <Link to="/aboutus.html">
                قراءة المزيد<i className="fa fa-angle-double-right"></i>
              </Link>
              <ul className="footer-social">
                <li>
                  <a target="_blank" href="/facebook">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/facebook">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/facebook">
                    <i className="fa fa-google-plus"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/facebook">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/facebook">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </aside>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-6">
            <aside id="nav_menu-1" className="widget widget_nav_menu">
              <h3 className="widget-title"> {cityName} خدماتنا</h3>
              <div className="menu-our-services-container">
                <ul id="menu-our-services" className="menu">
                  <li>
                    <Link
                      to={city_url + "local-home-movers.html"}
                      title=" {cityName} التحركات المحلية"
                    >
                      {cityName} التحركات المحلية
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={city_url + "long-distance-movers.html"}
                      title="{cityName} المسافة الطويلة"
                    >
                      {cityName} المسافة الطويلة
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={city_url + "packing-unpacking-movers.html"}
                      title=" {cityName} التعبئة"
                    >
                      {cityName} التعبئة
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={city_url + "mover-storage.html"}
                      title=" {cityName} نقل التخزين"
                    >
                      {cityName} نقل التخزين
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={city_url + "auto-transport-mover.html"}
                      title=" {cityName} نقل السيارات"
                    >
                      {cityName} نقل السيارات
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={city_url + "packing-unpacking-movers.html"}
                      title=" {cityName} تفريغ"
                    >
                      {cityName} تفريغ
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={city_url + "household-moving.html"}
                      title=" {cityName} نقل الأسرة"
                    >
                      {cityName} نقل الأسرة
                    </Link>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-6">
            <aside id="image-upload-1" className="widget widget_image_upload">
              <div className="image_upload">
                <img
                  src={footer}
                  title="شركه أمين لنقل الأثاث {cityName}"
                  alt="شركه أمين لنقل الأثاث {cityName}"
                />
              </div>
            </aside>
          </div>
          <div className="bottom-fixed">
            <div className="phone">
              <a href="tel:00971503664575" title="tewt">
                <img src={whatsapp} alt="050-366-45-75" width="30" />
                050-366-45-75
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
