import React, { Component } from "react";
import { Link } from "react-router-dom";
import whatsapp from "./../img/whatsapp.png";
import footer from "./../img/footer.png";
import { Row, Col } from "reactstrap";
//import { createBrowserHistory as createHistory } from 'history';
//const customHistory = createHistory();

class Footer extends Component {
  render() {
    //console.log();
    var areaAddress = "";
    var cityName = window.config.name_en;
    var city_url = "/sharjah/";
    if (this.props.areaAddress !== "") {
      areaAddress = this.props.areaAddress;
    }
    if (this.props.city_url !== "") {
      city_url = "/en/" + this.props.city_url + "/";
    }
    return (
      <footer
        id="footer-main"
        className="footer-main container-fluid no-left-padding no-right-padding"
      >
        <div className="container">
          <Row className="footer-address-box">
            <Col className="col-md-4 col-sm-6 col-xs-6">
              <div className="address-info">
                <p>
                  Amin furniture movers in {cityName} <br /> {areaAddress}
                  <i className="icon icon-Pointer"></i>
                </p>
              </div>
            </Col>
            <Col className="col-md-4 col-sm-6 col-xs-6">
              <div className="address-info">
                <p>
                  <i className="icon icon-Phone2"></i>
                  <a href="tel:18001234567" title="18001234567">
                    office: 1800 123 4567
                  </a>
                  <a href="tel:18001234568" title="18001234567">
                    fax: 1800 123 4568
                  </a>
                </p>
              </div>
            </Col>
            <Col className="col-md-4 col-sm-6 col-xs-6">
              <div className="address-info">
                <p>
                  <i className="icon icon-Timer"></i> Monday to Saturday: 10 am
                  to 5 pm <span>Sunday Closed</span>
                </p>
              </div>
            </Col>
          </Row>
          <div className="col-md-4 col-sm-6 col-xs-6">
            <aside id="widget-about-1" className="widget widget_about">
              <h3 className="widget-title">About Us</h3>
              <div className="menu-our-services-container">
                <ul id="menu-our-services" className="menu">
                  <li>
                    <Link to="/en/dubai/">Dubai Movers</Link>
                  </li>
                  <li>
                    <Link to="/en/sharjah/">Sharjah Movers</Link>
                  </li>
                  <li>
                    <Link to="/en/abudhabi/">Abu Dhabi Movers</Link>
                  </li>
                  <li>
                    <Link to="/en/alain/">Alain Movers</Link>
                  </li>
                  <li>
                    <Link to="/en/ajman/">Ajman Movers</Link>
                  </li>
                  <li>
                    <Link to="/en/rak/">Ras alkhaima Movers</Link>
                  </li>
                  <li>
                    <Link to="/en/uaq/">Umm Al Quwain Movers</Link>
                  </li>
                  <li>
                    <Link to="/en/fujaira/">Fujaira Movers</Link>
                  </li>
                </ul>
              </div>

              <p>
                {cityName} Furniture Transfer Company is a furniture transport
                secretary in the field of bagging, we have long experience in
                unpacking, installing, packaging and transporting furniture,
                safe transport, without scratches or fractures and without
                losing any piece of furniture, and without damaging any
                furniture.
              </p>
              <Link to="/about-us.html">
                Read More<i className="fa fa-angle-double-right"></i>
              </Link>
              <ul className="footer-social">
                <li>
                  <a target="_blank" href="/facebook">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/facebook">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/facebook">
                    <i className="fa fa-google-plus"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/facebook">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/facebook">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </aside>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-6">
            <aside id="nav_menu-1" className="widget widget_nav_menu">
              <h3 className="widget-title">Our Services</h3>
              <div className="menu-our-services-container">
                <ul id="menu-our-services" className="menu">
                  <li>
                    <Link to={city_url + "local-home-movers.html"}>
                      Local Movers
                    </Link>
                  </li>
                  <li>
                    <Link to={city_url + "long-distance-movers.html"}>
                      Long Distance
                    </Link>
                  </li>
                  <li>
                    <Link to={city_url + "packing-unpacking-movers.html"}>
                      Packing
                    </Link>
                  </li>
                  <li>
                    <Link to={city_url + "mover-storage.html"}>
                      Storage Moving
                    </Link>
                  </li>
                  <li>
                    <Link to={city_url + "auto-transport-movers.html"}>
                      Auto Transport
                    </Link>
                  </li>
                  <li>
                    <Link to={city_url + "packing-unpacking-movers.html"}>
                      Unpacking
                    </Link>
                  </li>
                  <li>
                    <Link to={city_url + "household-moving.html"}>
                      Household Moving/Packing
                    </Link>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-6">
            <aside id="image-upload-1" className="widget widget_image_upload">
              <div className="image_upload">
                <img src={footer} alt="" />
              </div>
            </aside>
          </div>
        </div>

        <div className="bottom-fixed">
          <div className="phone">
            <a href="tel:00971503664575" title="tewt">
              <img src={whatsapp} alt="050-366-45-75" width="30" />
              050-366-45-75
            </a>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
