import React, {Component}  from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import './App.css';

import Header from './Header';
import Footer from './Footer';
import PageLinks from './PageLinks';
import Meta from './Meta';
import Breadcrumbs from './Breadcrumbs';
class NotFound extends Component{
  
     
    render(){
        var title = 'نقل اثاث';
		var cityName = 'الشارقة';
		if(this.props.areaTitle !==''){
			title = this.props.areaTitle;
			//cityName = this.props.areaTitle;
		}
        return(
            <div>
            <Header  />
            <Meta areaTitle = 'الصفحة غير موجودة'/>
            <Breadcrumbs areaTitle='الصفحة غير موجودة' areaAddress= ''  city_name={cityName}/>
            <Container className="about-section container-fluid no-left-padding no-right-padding">
                <Row className="container">
                    
                    <Col className="container col-md-12 col-12">
                        <div className="section-header pt-4 ">
                            <h1>الصفحة غير موجودة</h1>                           
                        </div>
                        <div className="about-content">                           
                            <Link to="/" title="إعادة التوجيه إلى الصفحة الرئيسية">إعادة التوجيه إلى {title} الصفحة الرئيسية</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
            <PageLinks />     
            <Footer />
            </div>
        );
    }
   
}

export default NotFound;