import React, { Component } from "react";

import Header from "./Header";
import ServicesContent from "./ServicesContent";
import Footer from "./Footer";
import Meta from "./Meta";
import Breadcrumbs from "./Breadcrumbs";
class Aboutus extends Component {
  constructor(props) {
    super(props);
    document.title = "Our services";
    // document.description = 'Our services';
  }

  render() {
    var title = "Services in Furniture Movers";
    var cityName = window.config.name_en;

    return (
      <div>
        <Header />
        <Meta areaTitle={title} areaAddress="" city_name={cityName} />
        <Breadcrumbs
          areaTitle={title}
          areaAddress={title}
          city_name={cityName}
          city_url="sharjah"
        />
        <ServicesContent areaTitle={this.props.areaTitle} />
        <Footer />
      </div>
    );
  }
}

export default Aboutus;
