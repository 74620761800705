import React, { Component } from "react";
import { Link } from "react-router-dom";
import service1 from "./img/service1.jpg";
import service2 from "./img/service2.jpg";
import service3 from "./img/service3.jpg";

class ServicesContent extends Component {
  render() {
    var title = "نقل اثاث";
    var cityName = "الشارقة";
    var city_url = "/sharjah/";
    if (this.props.city_url !== "" && this.props.city_url !== undefined) {
      city_url = "/" + this.props.city_url + "/";
    }
    if (this.props.areaTitle !== undefined && this.props.areaTitle !== "") {
      title = this.props.areaTitle;
      //cityName = this.props.areaTitle;
    }
    //console.log(title);
    return (
      <div className="services-section container-fluid no-left-padding no-right-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12 col-xs-12 srv-info">
              <div className="section-header">
                <h2> خدماتنا {title}</h2>
                <h3>
                  ماذا <span> {cityName} </span> المحركون
                </h3>
              </div>
              <p>
                شركة نقل عفش الشارقة هى شركة أمين في مجال نقل العفش، لدينا خبرة
                طويلة فى فك وتركيب وتغليف ونقل العفش، نقل آمن، بدون خدوش أو كسور
                وبدون فقدان أى قطعة أثاث، وبدون تضرر أي قطعة أثاث.
              </p>
              <Link to="/aboutus.html" title="قراءة المزيد">
                قراءة المزيد
              </Link>
            </div>
            <div className="col-md-8 col-sm-12 col-xs-12 service-blocks">
              <div className="row">
                <div className="col-md-4 col-sm-4 col-xs-4">
                  <div className="srv-content">
                    <Link to="packers-movers.html">
                      <img
                        width="230"
                        height="153"
                        src={service1}
                        alt="نقل عفش"
                      />
                    </Link>
                    <div className="srv-box">
                      <h5>
                        <Link to={city_url + "packers-movers.html"}>
                          {" "}
                          نقل عفش
                        </Link>
                      </h5>
                      <p>
                        {" "}
                        مع شركة نقل عفش الشارقة وداعا للقلق الذى كنت تشعر به عند
                        نقل اثاث منزلك . نحن نقوم بالنقل باحترافية تامة وبدون اى
                        خدوش او كسور. نقل العفش داخل وخارج المدينه [...]
                      </p>
                      <Link
                        to={city_url + "packers-movers.html"}
                        title="قراءة المزيد"
                      >
                        قراءة المزيد
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-4">
                  <div className="srv-content">
                    <Link to="home-movers.html">
                      <img
                        width="230"
                        height="153"
                        src={service2}
                        alt="نقل عفش"
                      />
                    </Link>
                    <div className="srv-box">
                      <h5>
                        <Link to={city_url + "home-movers.html"}>
                          الانتقال والتخزين
                        </Link>
                      </h5>
                      <p>
                        {" "}
                        تسعى شركة أمين دائماً في توفير حلول ومنتجات متكاملة لحفظ
                        وتخزين نقل اثاث والوثائق مع الاخذ في الاعتبار نوع نقل
                        اثاث والوثائق المراد حفظها ، حفظ الأشرطة وتختلف عن حاجة
                        حفظ المخططات الهندسية ،لان كل نوع من انواع نقل اثاث
                        يناسبه نوع محدد من انواع انظمة التخزين [...]
                      </p>
                      <Link
                        to={city_url + "home-movers.html"}
                        title="قراءة المزيد"
                      >
                        قراءة المزيد
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-4">
                  <div className="srv-content">
                    <Link to="furniture-movers.html">
                      <img
                        width="230"
                        height="153"
                        src={service3}
                        alt="نقل عفش"
                      />
                    </Link>
                    <div className="srv-box">
                      <h5>
                        <Link to={city_url + "furniture-movers.html"}>
                          نقل اثاث
                        </Link>
                      </h5>
                      <p>
                        {" "}
                        مع شركة نقل عفش الشارقة وداعا للقلق الذى كنت تشعر به عند
                        نقل اثاث منزلك . نحن نقوم بالنقل باحترافية تامة وبدون اى
                        خدوش او كسور. [...]
                      </p>
                      <Link
                        to={city_url + "furniture-movers.html"}
                        title="قراءة المزيد"
                      >
                        قراءة المزيد
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesContent;
