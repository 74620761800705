import React, {Component} from 'react';

import Header from './Header';
import AboutContent from './AboutContent';
import Footer from './Footer';
import Meta from "./Meta";
import Breadcrumbs from './Breadcrumbs'

class Aboutus extends Component{
 


  render(){
    var cityName = window.config.name_en;
		if(this.props.areaTitle !==''){
			cityName = this.props.areaTitle;
		}
    return(
      <div>
        <Header />
        <Meta areaTitle='About us ' areaAddress= '' city_name={cityName}/>
        <Breadcrumbs areaTitle='Movers information'  areaAddress= ''  city_name={cityName}/>
        
        <AboutContent />     
        <Footer />
       </div>
    );
}
  
  
}


export default Aboutus;
