import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import aboutImg from "./../img/aboutImg.jpg";
class AboutContent extends Component {
  render() {
    var cityName = window.config.name_en;

    return (
      <Container className="about-section container-fluid no-left-padding no-right-padding">
        <Row className="container">
          <Col className="about-img col-md-6 col-12">
            <img width="458" height="324" src={aboutImg} className="" alt="" />
          </Col>
          <Col className="container col-md-6 col-12">
            <div className="section-header pt-4 ">
              <h5>معلومات عنا</h5>
              <h3>
                You are welcome in <span>{cityName} Furniture Movers </span>
              </h3>
            </div>
            <div className="about-content">
              <p>
                {cityName} Furniture Transfer Company is a furniture transport
                secretary in the field of bagging, we have long experience in
                unpacking, installing, packaging and transporting furniture,
                safe transport, without scratches or fractures and without
                losing any piece of furniture, and without damaging any
                furniture.
              </p>
              <h5>
                Some of our <span>Sincerity,</span>
              </h5>
              <ul>
                <li> Our business is doing very fast. </li>
                <li>
                  {" "}
                  Commercial transport (offices, hotels, warehouses, etc.){" "}
                </li>
                <li> Highly trained packing personnel. </li>
                <li>
                  {" "}
                  Assembling flat pack furniture (IKEA, Home Center, etc.){" "}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AboutContent;
