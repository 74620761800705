import React, { Component } from "react";
import { Link } from "react-router-dom";
import service1 from "./../img/service1.jpg";
import service2 from "./../img/service2.jpg";
import service3 from "./../img/service3.jpg";

class ServicesContent extends Component {
  render() {
    var cityName = window.config.name_en;
    var title = cityName;
    if (this.props.areaTitle !== "") {
      title = this.props.areaTitle;
    }
    if (title === "") title = cityName;
    var city_url = "/en/sharjah/";
    if (this.props.city_url !== "" && this.props.city_url !== undefined) {
      city_url = "/en/" + this.props.city_url + "/";
    }

    return (
      <div className="services-section container-fluid no-left-padding no-right-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12 col-xs-12 srv-info">
              <div className="section-header">
                <h1> Movers Services in {title}</h1>
                <h3>
                  What is <span> {cityName} </span> Movers
                </h3>
              </div>
              <p>
                Sharjah Luggage movers Company is an honest company in the field
                of luggage transport, we have long experience in unpacking,
                installing, packing and transporting luggage, safe transport,
                without scratches or fractures and without losing any piece of
                furniture, and without damaging any piece of furniture.
              </p>
              <Link to="/en/aboutus.html" title="Read Mover">
                Read Mover
              </Link>
            </div>
            <div className="col-md-8 col-sm-12 col-xs-12 service-blocks">
              <div className="row">
                <div className="col-md-4 col-sm-4 col-xs-4">
                  <div className="srv-content">
                    <Link to="packers-movers.html">
                      <img
                        width="230"
                        height="153"
                        src={service1}
                        alt="نقل عفش"
                      />
                    </Link>
                    <div className="srv-box">
                      <h5>
                        <Link to={city_url + "packers-movers.html"}>
                          Relocation
                        </Link>
                      </h5>
                      <p>
                        {" "}
                        With the Luggage Transfer Company of Sharjah, say
                        goodbye to the worry you were feeling when moving your
                        home furniture. We transport professionally, without any
                        scratches or fractures. Transfer of luggage to and from
                        the city [...]
                      </p>
                      <Link
                        to={city_url + "packers-movers.html"}
                        title="Read Mover"
                      >
                        Read Mover
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-4">
                  <div className="srv-content">
                    <Link to={city_url + "home-movers.html"}>
                      <img
                        width="230"
                        height="153"
                        src={service2}
                        alt="نقل عفش"
                      />
                    </Link>
                    <div className="srv-box">
                      <h5>
                        <Link to={city_url + "home-movers.html"}>Storing</Link>
                      </h5>
                      <p>
                        Amin company always seeks to provide integrated
                        solutions and products to save and store the transfer of
                        furniture and documents taking into account the type of
                        furniture transfer and the documents to be saved,
                        keeping the tapes [...]
                      </p>
                      <Link
                        to={city_url + "home-movers.html"}
                        title="Read Mover"
                      >
                        Read Mover
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-4">
                  <div className="srv-content">
                    <Link to={city_url + "furniture-movers.html"}>
                      <img
                        width="230"
                        height="153"
                        src={service3}
                        alt="نقل عفش"
                      />
                    </Link>
                    <div className="srv-box">
                      <h5>
                        <Link to={city_url + "furniture-movers.html"}>
                          Movers
                        </Link>
                      </h5>
                      <p>
                        {" "}
                        With the Sharjah Furniture Transfer Company, bye-bye,
                        you are worried when moving your home furniture. We
                        transport professionally, without any scratches or
                        fractures. We are best movers in UAE, have experince in
                        mover furnituer more [...]
                      </p>
                      <Link
                        to={city_url + "furniture-movers.html"}
                        title="Read Mover"
                      >
                        Read Mover
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesContent;
