import React, {Component} from 'react';

import Header from './Header';
import Slider from './Slider';
//import AboutContent from './AboutContent';
//import ServicesContent from './ServicesContent';
import Footer from './Footer';
import PageLinks from './PageLinks';
import Meta from "./Meta";
class App extends Component{
  

  render(){
    var name = window.config.name;
    return(
      <div>
      <Meta areaTitle='نقل اثاث' areaAddress= {name}   city_name={name}/>
      <Header />
      <Slider />
      <div className="about-section container">
        <h1>تتميز شركه أمين لنقل الأثاث بأنها تمتلك أسطول من سيارات نقل الأثاث</h1>
        </div>             
     
      
      <PageLinks url='' city_url='dubai' page='random'/>
        <PageLinks url='' city_url='sharjah' page='random'/>
        <PageLinks url='' city_url='abudhabi' page='random'/>
        <PageLinks url='' city_url='alain' page='random'/> 
        <PageLinks url='' city_url='ajman' page='random'/>
        <PageLinks url='' city_url='uaq' page='random'/>
        <PageLinks url='' city_url='rak' page='random'/>
        <PageLinks url='' city_url='fujaira' page='random'/>
      <Footer city_url='/sharjah/'/>
       </div>
    );
}
  
  
}


export default App;
