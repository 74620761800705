import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
const Breadcrumbs = props => {
  return (
    <div className="container-fluid no-left-padding no-right-padding breadcrumb ">
      <Breadcrumb className="container ">
        <BreadcrumbItem>
          <Link to="/en/" title="">
            Home
          </Link>
        </BreadcrumbItem>
        {props.city_url !== "" && (
          <BreadcrumbItem>
            <Link to={"/en/" + props.city_url + "/"} title="">
              {props.city_name}
            </Link>
          </BreadcrumbItem>
        )}
        <BreadcrumbItem active>
          <span>{props.areaTitle}</span>
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
};

export default Breadcrumbs;
