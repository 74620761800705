import React, {Component} from 'react';
import './App.css';

import Header from './Header';
import AboutContent from './AboutContent';
import Footer from './Footer';
import Meta from "./Meta";
import Breadcrumbs from './Breadcrumbs'

class Aboutus extends Component{
  

  render(){
    var cityName = 'الشارقة';
		if(this.props.areaTitle !==''){
			cityName = this.props.areaTitle;
		}
    return(
      <div>
        <Header />
        <Meta areaTitle=' معلومات عنا ' areaAddress= '' city_name={cityName}/>
        <Breadcrumbs areaTitle=' معلومات عنا '  areaAddress= ''  city_name={cityName}/>
        
        <AboutContent />     
        <Footer />
       </div>
    );
}
  
  
}


export default Aboutus;
