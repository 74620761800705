import React, { Component } from "react";
import { Helmet } from "react-helmet";

class Meta extends Component {
  render() {
    var title = "نقل اثاث";
    var cityName = window.config.name_en;
    if (this.props.areaTitle !== "") {
      title = this.props.areaTitle;
      //cityName = this.props.areaTitle;
    }
    var metastr = "";
    if (this.props.mata !== undefined) {
      metastr = this.props.mata;
      cityName = this.props.city_name;
    }

    //console.log(metastr);
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {title} in {cityName} Movers | Amin Furniture movers 0503664575 in UAE
        </title>
        <meta
          name="description"
          content={
            title +
            " in " +
            cityName +
            " | Ideal Furniture movers in " +
            title +
            " | Cheap movers in " +
            title
          }
        />
        {metastr}
      </Helmet>
    );
  }
}

export default Meta;
