import React, { Component } from "react";

import Header from "./Header";
import Slider from "./Slider";
//import AboutContent from './AboutContent';
//import ServicesContent from './ServicesContent';
import Footer from "./Footer";
import PageLinks from "./PageLinks";
import Meta from "./Meta";

class App extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    var name = "United Arab Emirates";
    window.config.name_en = name;
    return (
      <div>
        <Meta
          areaTitle="Furniture movers & packers"
          areaAddress={name}
          city_name={name}
        />
        <Header
          areaTitle="Furniture movers & packers"
          areaAddress={name}
          city_name={name}
        />
        <Slider
          areaTitle="Furniture movers & packers"
          areaAddress={name}
          city_name={name}
        />

        <PageLinks url="" city_url="dubai" page="random" />
        <PageLinks url="" city_url="sharjah" page="random" />
        <PageLinks url="" city_url="abudhabi" page="random" />
        <PageLinks url="" city_url="alain" page="random" />
        <PageLinks url="" city_url="ajman" page="random" />
        <PageLinks url="" city_url="uaq" page="random" />
        <PageLinks url="" city_url="rak" page="random" />
        <PageLinks url="" city_url="fujaira" page="random" />
        <Footer city_url="sharjah" />
      </div>
    );
  }
}

export default App;
